<script>
  // Extensions
  import View from '@/views/View'
  // Mixins
  import LoadSections from '@/mixins/load-sections'
  export default {
    name: 'Consejo',
    metaInfo: { title: 'El Consejo' },
    extends: View,
    mixins: [
      LoadSections([
        'hero-alt',
        //'ConvocatoriaCertificacion',
        //'ConvocatoriaRecertificacion',
        //'CitatorioAsamblea',
        'mesa-directiva',

        //'newsletter',
        'info-alt',
      ]),
    ],
    props: {
      id: {
        type: String,
        default: 'recent-works',
      },
    },
  }
</script>
